<template>
  <div class="containerHistory">
    <div class="first">
      <img src="../assets/history/redromb.png" alt="" />
    </div>
    <div class="second">
      <img src="../assets/history/angar.png" alt="" />
    </div>
    <div class="tres">
      <img src="../assets/history/fon.png" alt="" />
      <div class="redline">
        <img src="../assets/history/redline.png" alt="" />
      </div>
      <div class="title" v-html="containerHistoryTitle"></div>
      <div class="subtitle" v-html="containerHistorySubTitle"></div>
    </div>
  </div>
  <div class="containerfilm">
    <div class="video" v-if="ShowVideo" @click="ShowVideo = false">
      <img src="../assets/history/film.png" alt="" />
      <div class="play">
        <img src="../assets/history/play.png" alt="" />
      </div>
    </div>
    <div class="videoplay" v-else>
      <video autoplay loop class="myVideo">
        <source src="../assets/video/zpfilm.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="redromb">
      <img src="../assets/history/redrombfilm.png" alt="" />
      <div class="title">Посмотрите фильм о нас</div>
    </div>
  </div>
  <div class="containercitata">
    <div class="redline">
      <img src="../assets/history/redline.png" alt="" />
    </div>
    <div class="title" v-html="containercitata_title"></div>
  </div>
  <div class="containerphoto">
    <div class="img1">
      <img src="../assets/history/redromb2.png" alt="" />
    </div>
    <div class="img2">
      <img src="../assets/history/img2.png" alt="" />
    </div>
    <div class="img3">
      <img src="../assets/history/img3.png" alt="" />
    </div>
    <div class="img4">
      <img src="../assets/history/img4.png" alt="" />
    </div>
    <div class="img5"><img src="../assets/history/img5.png" alt="" /></div>
    <div class="img6">
      <img src="../assets/history/img6.png" alt="" />
    </div>
    <div class="img7">
      <img src="../assets/history/img7.png" alt="" />
    </div>
    <div class="title1" v-html="containerphoto_title1"></div>
    <div class="title2" v-html="containerphoto_title2"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ShowVideo: true,
      containerHistoryTitle: "",
      containerHistorySubTitle: "",
      containercitata_title: "",
      containerphoto_title1: "",
      containerphoto_title2: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cb96744.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.containerHistoryTitle = finalRes[11].text;
      this.containerHistorySubTitle = finalRes[12].text;
      this.containercitata_title = finalRes[13].text;
      this.containerphoto_title1 = finalRes[14].text;
      this.containerphoto_title2 = finalRes[15].text;
    },
  },
};
</script>
<style scoped>
@media only screen and (min-width: 1382px) {
  .containerHistory {
    position: relative;
    width: 100%;
    height: 675px;
  }
  .containerHistory .first {
    position: absolute;
    right: 68px;
    top: 57px;
  }
  .containerHistory .first img {
    width: 714px;
  }
  .containerHistory .second {
    position: absolute;
    right: 134px;
    top: 137px;
  }
  .containerHistory .second img {
    width: 604px;
  }
  .containerHistory .tres {
    position: absolute;
    top: 83px;

    z-index: -1;
  }
  .containerHistory .tres img {
    width: 806px;
  }
  .containerHistory .tres .redline {
    position: absolute;
    top: 185px;
    left: 85px;
  }
  .containerHistory .tres .redline img {
    width: 234px;
    height: 0px;
    border: 3px solid #ff4b00;
    transform: rotate(90deg);
  }
  .containerHistory .tres .title {
    position: absolute;
    top: 80px;
    left: 231px;
    width: 403px;
    font-family: RFD-Light;
    font-weight: normal;
    font-size: 30px;
    line-height: 130%;
    color: white;
  }
  .containerHistory .tres .subtitle {
    position: absolute;
    top: 360px;
    left: 231px;
    width: 403px;
    font-family: RFD-Light;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: white;
  }
  .bF {
    font-family: RFD-Regular;
    font-weight: bold;
  }
  .containerfilm {
    position: relative;
    width: 1178px;
    margin: 0 auto;
    height: 633px;
    z-index: 1;
  }
  .containerfilm .video img {
    width: 100%;
  }
  .containerfilm .video .play {
    position: absolute;
    top: 250px;
    left: 555px;
  }
  .containerfilm .video .play:hover {
    position: absolute;
    top: 250px;
    left: 555px;
    box-shadow: 0px 0px 30px rgba(255, 75, 0, 0.5);
  }
  .containerfilm .video .play img {
    width: 60px;
    border-radius: 50px;
    /* border: 90px; */
    background-color: white;
    box-shadow: 0px 0px 30px rgb(255 75 0 / 50%);
  }
  .containerfilm .redromb {
    position: absolute;
    right: 71px;
    top: 496px;
  }
  .containerfilm .redromb img {
    width: 388px;
  }
  .containerfilm .redromb .title {
    font-family: RFD-Regular;
    font-size: 30px;
    width: 308px;
    text-align: center;
    position: absolute;
    top: 25px;

    left: 35px;
    color: white;
  }
  .containerfilm .videoplay {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 563px;
  }
  .containerfilm .videoplay .myVideo {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 1178px;
    height: 563px;
  }

  .containercitata {
    position: relative;
    margin: 0 auto;
    background-color: #f0f0f0;
    width: 1440px;
    height: 538px;
    top: -200px;

    z-index: -2;
  }
  .containercitata .redline {
    position: absolute;
    top: 337px;
    left: 85px;
  }
  .containercitata .redline img {
    width: 234px;
    height: 0px;
    border: 3px solid #ff4b00;
    transform: rotate(90deg);
  }
  .containercitata .title {
    font-family: RFD-Light;
    font-size: 30px;
    width: 1033px;
    position: absolute;
    line-height: 130%;
    left: 236px;
    top: 237px;
  }
  .containercitata .title b {
    font-family: RFD-Regular;
    font-size: 30px;
  }
  .containerphoto {
    position: relative;
    margin: 0 auto;
    width: 1440px;
    height: 1100px;
    margin-top: -150px;
  }
  .containerphoto .img1 img {
    position: absolute;
    width: 877px;

    right: 130px;
  }
  .containerphoto .img2 img {
    position: absolute;
    width: 604px;
    left: 0px;
    top: 27px;
    filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
  }
  .containerphoto .img3 img {
    position: absolute;
    width: 433px;
    left: 201px;
    top: 372px;
    filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
  }
  .containerphoto .img4 img {
    position: absolute;
    width: 705px;
    right: 0px;
    top: 292px;
    filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
  }
  .containerphoto .img5 img {
    position: absolute;
    width: 466px;
    right: 340px;
    top: 717px;
    filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
  }
  .containerphoto .img6 img {
    position: absolute;
    height: 202px;
    left: 0px;
    top: 345px;
    z-index: -1;
  }
  .containerphoto .img7 img {
    position: absolute;
    width: 139px;
    right: 0px;
    top: 687px;
    z-index: -1;
  }
  .containerphoto .title1 {
    font-family: RFD-Light;
    font-size: 14px;
    position: absolute;

    line-height: 130%;
    right: 231px;
    width: 474px;
    top: 27px;
  }
  .containerphoto .title2 {
    font-family: RFD-Light;
    font-size: 14px;
    position: absolute;
    line-height: 130%;
    left: 130px;
    width: 474px;
    top: 717px;
  }
}
</style>
